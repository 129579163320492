@import url("./assets/css/boxicons.min.css");
@import url("./assets/css/bootstrap-icons.css");
@import url("./assets/css/animate.css");
@import url("./assets/css/swiper-bundle.min.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/style.css");

:root {
  --scrollWidth: -1800px;
  --duration: 25s;
}

.hover-custom:hover {
  color: #cf2435 !important;
}

.modal-custom {
  border: 1px solid #2d2d2d !important;
  background-color: #17181a !important;
  width: 100%;
  max-height: 80vh;
  padding: 30px 25px;
  position: relative;
  overflow: auto;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  cursor: auto !important;
  text-align: center;
}

.modal-custom button {
  border: 1px solid #2d2d2d !important;
  background-color: #17181a !important;
  color: white;
  margin-top: 20px;
  font-family: "Saira Condensed", sans-serif !important;
  font-size: 25px !important;
  cursor: pointer !important;
  padding: 10px;
  width: 150px;
}

.modal-custom button:hover {
  border: 1px solid #cf2435 !important;
  transition-duration: 0.5s;
  color: #cf2435;
  margin-top: 20px;
  font-family: "Saira Condensed", sans-serif !important;
  font-size: 25px !important;
  cursor: pointer !important;
}

.selected-button {
  border: 1px solid #cf2435 !important;
  transition-duration: 0.5s;
  color: #cf2435 !important;
  margin-top: 20px;
  font-family: "Saira Condensed", sans-serif !important;
  font-size: 25px !important;
  cursor: pointer !important;
}

.data-input-custom {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
  max-width: 465px;
}

.data-input-custom p {
  width: 100%;
  margin-top: 10px;
  color: rgb(204, 6, 6);
}

.error-msg {
  width: 100%;
  margin-top: 10px;
  color: rgb(204, 6, 6);
}

.data-input-custom h4 {
  width: 150px;
  margin: 0;
  color: white !important;
}

.data-input-custom input {
  cursor: auto !important;
  font-family: "Saira Condensed", sans-serif !important;
  font-size: 25px !important;
  width: 300px;
}
.data-input-custom select {
  cursor: auto !important;
  font-family: "Saira Condensed", sans-serif !important;
  font-size: 25px !important;
  width: 300px;
}

.data-input-custom textarea {
  cursor: auto !important;
  font-family: "Saira Condensed", sans-serif !important;
  font-size: 25px !important;
  width: 300px;
  height: 200px;
}

.data-input-custom .text-editor {
  cursor: auto !important;
  font-family: "Saira Condensed", sans-serif !important;
  background-color: white !important;
  width: 300px;
}

.data-input-custom .text-editor button {
  background-color: #fff !important;
  border: 1px solid #2d2d2d !important;
  color: white;
  margin-top: 1px;
}

.data-input-custom .text-editor p {
  width: 100%;
  margin-top: 0px;
  color: black !important;
}

.styled-text a {
  color: blue;
  text-decoration: underline;
}

.styled-text ul {
  padding-left: 2rem;
  list-style: disc;
}

.icon-input-custom {
  display: flex;
  width: 300px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.icon-input-custom .remove-image {
  display: inline;
  position: absolute;
  top: -27px;
  right: -11px;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  padding: 0px;
  font: 500 21px/20px sans-serif;
  transition: background 0.5s;
}

.icon-input-custom input {
  width: 300px;
}

.add-section-comment {
  display: flex;
  justify-content: space-around;
}

.none-background {
  background-color: none !important;
  /* width: 300px; */
}

.none-background button {
  border: 0px solid #2d2d2d !important;
  background-color: white !important;
  color: black;
  margin-top: 0px;
  font-family: "Saira Condensed", sans-serif !important;
  font-size: 25px !important;
  cursor: pointer !important;
  padding: auto;
  width: auto;
}

.none-background button:hover {
  border: 0px solid #2d2d2d !important;
  background-color: white !important;
  color: black;
  margin-top: 0px;
  font-family: "Saira Condensed", sans-serif !important;
  font-size: 25px !important;
  cursor: pointer !important;
  padding: auto;
  width: auto;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-auto {
  cursor: auto !important;
}

/* ================================== Preloader ========================= */

.our-preloader {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: #fff;
}

.our-preloader img {
  display: block;
  max-width: max-content;
  width: 80%;
}

/* ============================= Breaking News ====================== */
.news-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  background-color: #0079b3;
  z-index: 3;
  overflow: hidden;
  white-space: nowrap;
}

.news-container .title {
  position: absolute;
  background: #df2020;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #fff;
  font-weight: bold;
  font-style: italic;
  z-index: 200;
}

.news-container .title::before {
  content: "";
  position: absolute;
  width: 0;
  border-width: 50px;
  border-style: solid;
  border-color: #df2020 transparent transparent transparent;
  top: 0;
  right: -50px;
  z-index: -1;
}

.news-container ul {
  display: flex;
  list-style: none;
  margin: 0;
  /* transform: translateX(-800px); */
  animation: scroll var(--duration) infinite linear;
}

.news-container ul:hover {
  animation-play-state: paused;
}

.news-container ul li {
  white-space: nowrap;
  padding: 10px 40px;
  color: #fff;
  position: relative;
}

.news-container ul li::before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}

.contact-form-updated input,
.contact-form-updated textarea,
.contact-form-updated button,
.contact-form-updated .comment-form-btn svg {
  color: #555555 !important;
  fill: #555555 !important;
  /* background-color: green !important; */
}

.contact-form-updated .error {
  color: red;
}

.contact-form-updated input::placeholder,
.contact-form-updated textarea::placeholder {
  color: rgb(141, 141, 141) !important;
  /* background-color: green !important; */
}

/* .news-container ul li:last-child::before {
  display: none;
} */

@media only screen and (max-width: 576px) {
  .news-container .title {
    display: none;
  }
}

@keyframes scroll {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(var(--scrollWidth));
  }
}

/* .breaking-news-modal {
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 20%;
  margin-left: 1%;
  display: none;
  justify-content: center;
  flex-direction: column;
}
.breaking-news-modal-mob {
  display: flex;
}

.breaking-news-item {
  height: fit-content;
  opacity: 0.4;
  background: #cf2435 !important;
  background-color: #cf2435 !important;
  margin-bottom: 1%;
}

.breaking-news-item:hover {
  opacity: 0.7;
}

.breaking-news-title {
  -webkit-text-stroke: 2px #cf2435 !important;
  -webkit-text-fill-color: transparent;
  font-family: sans-serif;
  font-size: 60px;
  font-weight: bold;
  animation: disappear 1.5s ease-in-out infinite alternate;
}
.breaking-news-title-mob {
  -webkit-text-stroke: 2px #cf2435 !important;
  -webkit-text-fill-color: transparent;
  animation: disappear 1.5s ease-in-out infinite alternate;
} */

/* @keyframes disappear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
  .breaking-news-modal {
    top: 25%;
    display: flex;
  }
  .breaking-news-modal-mob {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .breaking-news-modal {
    top: 40%;
    display: flex;
  }
  .breaking-news-modal-mob {
    display: none;
  }
} */

/* .ticker-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  margin: auto;
  z-index: 3;
} 

 .big-heading {
  display: block;
  position: absolute;
  font-style: italic;
  font-size: 40px;
  background: #e60000;
  color: #fff;
  text-transform: uppercase;
  padding: 9px;
  letter-spacing: 2px;
  font-weight: 700;
  z-index: 2;
}

.big-heading::before {
  content: "";
  width: 0;
  position: absolute;
  border-width: 78px;
  border-style: solid;
  top: 0;
  z-index: -1;
}
.big-heading::before {
  border-color: #e60000 transparent transparent transparent;
  right: -78px;
} 
 .big-heading::after {
  border-color: transparent transparent #e60000 transparent;
  left: -78px;
  top: -78px;
} 

.text-update {
  height: 78px;
  background: #0079b3;
  overflow: hidden;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 500;
  display: flex;
  align-items: center;
  left: 12px;
}

.text-update p {
  position: absolute;
  width: 150%;
  margin: 0;
  padding: 15px 0px;
  font-weight: 700;
  text-align: left;
  transform: translateX(100%);
  animation: moving 30s 0s linear infinite;
} 

.text-update p:hover {
  animation-play-state: paused;
}

@keyframes moving {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 400ms ease 0ms;
}
.tiny {
  filter: blur(20px);
  transition: visibility 0ms ease 400ms;
}

.ql-container {
  font-size: 25px;
  color: black !important;
}
/* .quill-editor .ql-editor {
  color: black;
} */
